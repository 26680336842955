import useSWR, { mutate } from 'swr';
import { Account } from '@Types/account/Account';
import { Address, ValidateAddress } from '@Types/account/Address';
import { REMEMBER_ME } from 'helpers/constants/localStorage';
import { revalidateOptions } from 'frontastic';
import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub';
import { SubscriptionList } from '@Types/communication-preference/SubscriptionList';
export interface GetAccountResult {
  loggedIn: boolean;
  account?: Account;
  error?: ResponseError;
}

export interface UpdateAccount {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  birthdayYear?: number;
  birthdayMonth?: number;
  birthdayDay?: number;
  emailSubscription?: boolean;
  newCatalogPreviews?: boolean;
  trendingLooksAndStyles?: boolean;
  newItemIntroductions?: boolean;
  decorateTipsAndIdeas?: boolean;
  salesAndSpecial?: boolean;
}

export interface RegisterAccount extends UpdateAccount {
  email: string;
  password: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export const getAccount = (): GetAccountResult => {
  const result = useSWR<Account | GetAccountResult>('/action/account/getAccount', fetchApiHub, revalidateOptions);

  const account = (result.data as GetAccountResult)?.account || (result.data as Account);

  if (account?.accountId && account?.confirmed) return { account, loggedIn: true };

  return {
    loggedIn: false,
    account: undefined,
    error: result.error,
  };
};

export const login = async (email: string, password: string, remember?: boolean): Promise<Account> => {
  const payload = {
    email,
    password,
    remember,
  };
  const res = await fetchApiHub('/action/account/login', { method: 'POST' }, payload);
  if (res?.isLoggedIn && remember) {
    window.localStorage.setItem(REMEMBER_ME, '1');
  }
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart', res);
  await mutate('/action/wishlist/getWishlist', res);
  await mutate('/action/authorize/getCards', res);
  await mutate('/action/cart/recentOrder', res);

  return res;
};

export const impersonateUser = async (email: string): Promise<Account> => {
  const payload = {
    email,
  };
  const res = await fetchApiHub('/action/account/impersonateUser', { method: 'POST' }, payload);
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart', res);
  await mutate('/action/wishlist/getWishlist', res);
  await mutate('/action/authorize/getCards');
  await mutate('/action/cart/recentOrder', res);
  return res;
};

export const logout = async () => {
  window.localStorage.removeItem(REMEMBER_ME);
  const res = await fetchApiHub('/action/account/logout', { method: 'POST' });
  await mutate('/action/cart/getCart', res);
  await mutate('/action/account/getAccount', res);
  await mutate('/action/wishlist/getWishlist');
  return res;
};

export const register = async (account: RegisterAccount): Promise<Account> => {
  const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account);
  await mutate('/action/cart/recentOrder', response);
  return response;
};

export const confirm = async (token: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
  const payload = {
    email,
    password,
  };
  const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload);
  return res;
};
export const signupNewsLetter = async (email: string): Promise<void> => {
  const payload = {
    email,
  };
  const res = await fetchApiHub('/action/account/signupNewsLetter', { method: 'POST' }, payload);
  return res;
};

export const changePassword = async (oldPassword: string, newPassword: string): Promise<Account> => {
  return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword });
};

export const requestPasswordReset = async (email: string): Promise<void> => {
  const payload = {
    email,
  };

  return await fetchApiHub('/action/account/requestReset', { method: 'POST' }, payload);
};

export const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/reset', { method: 'POST' }, { token, newPassword });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const update = async (account: UpdateAccount): Promise<Account> => {
  const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const updateAddress = async (address: Address): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};
export const validateAddress = async (address: Address): Promise<ValidateAddress> => {
  const res = await fetchApiHub('/action/account/validateAddress', { method: 'POST' }, address);
  return res;
};
export const validateGuestCheckout = async (email: string): Promise<any> => {
  const res = await fetchApiHub('/action/account/validateGuestCheckout', { method: 'POST' }, { email });
  return res;
};
export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart', res);
  return res;
};

export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const getCustomerAddresses = async (): Promise<Address[]> => {
  const res = await fetchApiHub('/action/account/getCustomerAddresses', { method: 'POST' }, {});
  //   await mutate('/action/account/getAccount', res);
  return res;
};

export const getShippingLocations = () => {
  return useSWR('/action/project/getShippingLocations', fetchApiHub, revalidateOptions);
};

export const checkSubscription = async (email: String): Promise<SubscriptionList> => {
  const res = await fetchApiHub('/action/account/checkSubscription', { method: 'POST' }, { email });
  return res;
};
export const updateSubscription = async (payload: SubscriptionList): Promise<SubscriptionList> => {
  const res = await fetchApiHub('/action/account/updateSubscription', { method: 'POST' }, { ...payload });
  return res;
};

export const catalogRequest = async (email: string, values: any): Promise<void> => {
  const payload = {
    email,
    values,
  };
  const res = await fetchApiHub('/action/account/catalogRequest', { method: 'POST' }, payload);
  return res;
};

export const catalogUnsubscribe = async (email: string, values: any): Promise<void> => {
  const payload = {
    email,
    values,
  };
  const res = await fetchApiHub('/action/account/catalogUnsubscribe', { method: 'POST' }, payload);
  return res;
};

export const catalogUnsubscribeFreshDesk = async (email: string, values: any): Promise<void> => {
  const payload = {
    email,
    values,
  };
  const res = await fetchApiHub('/action/account/catalogUnsubscribeFreshDesk', { method: 'POST' }, payload);
  return res;
};
